import {
  convertModelDefinition,
  emailRegex,
  valueDef,
} from "react-forms-state/src";
import Translations from "translations/types";

export const loginPageModelDefinition = convertModelDefinition({
  ...valueDef("email"),
  ...valueDef("password"),
  ...valueDef("challenge"),
  ...valueDef("token"),
});

export const validateSingleSignOn = (
  value: { email: string | null },
  { translations }: { translations: Translations },
) => {
  let validation: any = true;
  if (!value.email || !emailRegex.test(value.email)) {
    validation = { email: translations.login.loginPage.emailValidation };
  }

  return validation;
};
export const validateLogin = (
  value: { email: string | null; password: string | null },
  { translations }: { translations: Translations },
) => {
  let validation: any = true;
  if (!value.email || !emailRegex.test(value.email)) {
    validation = { email: translations.login.loginPage.emailValidation };
  }
  if (!value.password) {
    validation = {
      ...validation,
      password: translations.login.loginPage.passwordValidation,
    };
  }
  return validation;
};

export const validateChallenge = (
  value: { challenge: string | null },
  { translations }: { translations: Translations },
) => {
  let validation: any = true;

  if (!value.challenge) {
    validation = {
      challenge: translations.login.loginPage.challengeCodeValidation,
    };
  }
  return validation;
};
