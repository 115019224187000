import { useCallback, useEffect, useState } from "react";

export default function useDropdown(nonClickableTarget?: Element | null) {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<Element | null>(null);

  useEffect(() => {
    if (nonClickableTarget != null) {
      setAnchor(nonClickableTarget);
    }
  }, [nonClickableTarget != null]);

  const onOpen = useCallback((event?: any) => {
    if (event) setAnchor(event.currentTarget as Element);
    setOpen(true);
  }, []);
  const onClose = useCallback(() => setOpen(false), []);

  return { open, anchor, onOpen, onClose };
}
