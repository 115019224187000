import {
  CAPACITY_MAX_DAYS,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_TRANSPORT,
} from "core/consts";
import { getReasonTranslation } from "core/model/auctions";
import { addDaysUnixUtc } from "core/model/utils/dates";
import {
  AuctionRequest,
  Careprovider,
  GetOntologyType,
  NoCapacityReason,
  SearchType,
} from "core/types";
import {
  fromUnixTime,
  getHours,
  getMinutes,
  getUnixTime,
  isBefore,
  isToday,
  setHours,
  setMinutes,
  startOfDay,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { valueDef } from "react-forms-state";
import Translations from "translations/types";

export function getNoCapacityReasonsMessage({
  translations,
  getOntology,
  reasons = [],
}: {
  getOntology: GetOntologyType;
  reasons: Array<NoCapacityReason> | undefined;
  translations: Translations;
}): string {
  return Array.isArray(reasons)
    ? reasons
        .map(
          (reason) =>
            Array.isArray(reason.values) &&
            reason.values
              .map((reasonValue: number) =>
                getReasonTranslation({
                  reason: reason.type,
                  value: reasonValue,
                  translations,
                  getOntology,
                }),
              )
              .join(", "),
        )
        .join(", ")
    : "";
}

export function shouldHandleTime({
  searchType,
}: {
  searchType: SearchType | undefined;
}): boolean {
  const allowedSearchTypes: SearchType[] = [
    SEARCH_TYPE_HOSPITAL,
    SEARCH_TYPE_TRANSPORT,
  ];

  return searchType !== undefined && allowedSearchTypes.includes(searchType);
}

export function appendTimeToCapacityDate({
  capacityDate,
  capacityTime,
}: {
  capacityDate: number;
  capacityTime: Date;
}): number {
  const start = startOfDay(fromUnixTime(capacityDate));
  return getUnixTime(
    setHours(
      setMinutes(start, getMinutes(capacityTime)),
      getHours(capacityTime),
    ),
  );
}

export function setTimeToMidnight(capacityDate: number) {
  return getUnixTime(
    zonedTimeToUtc(startOfDay(fromUnixTime(capacityDate)), "UTC"),
  );
}

function validateDateAndTime({
  _capacity_time,
  dateKey,
  dateToValidate,
  required,
  translations,
}: {
  _capacity_time: number | Date | undefined;
  dateKey: string;
  dateToValidate: number | undefined;
  required?: boolean;
  translations: Translations;
}) {
  const timestampYesterday = addDaysUnixUtc(-1, { endOfDay: true });
  const timestampCapacityMax = addDaysUnixUtc(CAPACITY_MAX_DAYS, {
    endOfDay: true,
  });

  const errorMessageDate =
    translations.careproviderApp.settings.capacity.tileNoCapacity
      .errorDateInPast;
  const errorMessageTime =
    translations.careproviderApp.settings.capacity.tileNoCapacity
      .errorTimeInPast;
  const errorMessageTimeInPastDateValid =
    translations.careproviderApp.settings.capacity.tileNoCapacity
      .errorTimeInPastDateOkay;

  if (required && !dateToValidate && !_capacity_time) {
    return {
      [dateKey]: {
        customMessage: errorMessageDate,
      },
      _capacity_time: {
        customMessage: errorMessageTime,
      },
    };
  }

  if (dateToValidate && !_capacity_time) {
    if (isToday(new Date(dateToValidate * 1000))) {
      return {
        [dateKey]: {
          customMessage: errorMessageTimeInPastDateValid,
        },
        _capacity_time: {
          customMessage: errorMessageTime,
        },
      };
    }

    if (
      dateToValidate > timestampCapacityMax ||
      dateToValidate < timestampYesterday
    ) {
      return {
        [dateKey]: {
          customMessage: errorMessageDate,
        },
        _capacity_time: {
          customMessage: errorMessageTime,
        },
      };
    }

    return {
      _capacity_time: {
        customMessage: errorMessageTime,
      },
    };
  }

  if (!dateToValidate && _capacity_time) {
    return {
      [dateKey]: {
        customMessage: errorMessageDate,
      },
    };
  }

  if (dateToValidate && _capacity_time) {
    if (
      isToday(new Date(dateToValidate * 1000)) &&
      isBefore(new Date(_capacity_time), new Date())
    ) {
      return {
        [dateKey]: {
          customMessage: errorMessageTimeInPastDateValid,
        },
        _capacity_time: {
          customMessage: errorMessageTime,
        },
      };
    }

    // for time inputs it's possible to select todays date
    if (
      dateToValidate > timestampCapacityMax ||
      dateToValidate < timestampYesterday
    ) {
      return {
        [dateKey]: {
          customMessage: errorMessageDate,
        },
      };
    }
  }

  return true;
}

function validateSingleDate({
  dateKey,
  dateToValidate,
  required,
  translations,
}: {
  dateKey: string;
  dateToValidate: number | undefined;
  required?: boolean;
  translations: Translations;
}) {
  const timestampCurrent = addDaysUnixUtc(0, { endOfDay: true });
  const timestampCapacityMax = addDaysUnixUtc(CAPACITY_MAX_DAYS, {
    endOfDay: true,
  });
  const errorMessageDate =
    translations.careproviderApp.settings.capacity.tileNoCapacity
      .errorDateInPast;

  if (dateToValidate) {
    if (
      dateToValidate > timestampCapacityMax ||
      dateToValidate < timestampCurrent
    ) {
      return {
        [dateKey]: {
          customMessage: errorMessageDate,
        },
      };
    }
  }

  if (required && !dateToValidate) {
    return {
      [dateKey]: {
        customMessage: errorMessageDate,
      },
    };
  }

  return true;
}

export function validateCapacity({
  dateKey,
  props,
  required,
  searchType,
  value,
}: {
  dateKey: string;
  props: {
    auctionRequest?: AuctionRequest;
    formInputValue: Careprovider;
    translations: Translations;
  };
  required?: boolean;
  searchType: SearchType;
  value: {
    _capacity_time?: number | Date;
    available_date?: number;
    capacity_date?: number;
    has_capacity?: boolean;
  };
}) {
  const { translations } = props;
  const { _capacity_time, available_date, capacity_date, has_capacity } = value;
  const dateToValidate = capacity_date || available_date;
  const handleTime = shouldHandleTime({ searchType });

  if (has_capacity) return true;

  if (handleTime) {
    return validateDateAndTime({
      dateKey,
      dateToValidate,
      required,
      translations,
      _capacity_time,
    });
  }
  // Single capacity date validation
  return validateSingleDate({
    dateKey,
    dateToValidate,
    required,
    translations,
  });
}

export function getCapacityModel(location: "request" | "settings") {
  const isSettings = location === "settings";

  return {
    ...valueDef("has_capacity"),
    ...valueDef(isSettings ? "available_date" : "capacity_date", {
      convertOut: (
        value: number,
        props: { formInputValue: Careprovider },
        modelValues: Map<string, boolean | Date>,
      ) => {
        const has_capacity = modelValues.get("has_capacity") as boolean;
        const capacityTime = modelValues.get("_capacity_time") as Date;
        const searchType = props?.formInputValue.patient_type;

        // if has_capacity is set to true, remove the available_date
        if (has_capacity) return null;

        if (capacityTime && shouldHandleTime({ searchType })) {
          return appendTimeToCapacityDate({
            capacityDate: value,
            capacityTime,
          });
        }
        // if no capacity_time set and has_capacity is false, we set the available_date to midnight
        return setTimeToMidnight(value);
      },
    }),
    ...valueDef("_capacity_time", {
      convertIn: (_, props: { formInputValue: Careprovider }) => {
        const capacityDate = props?.formInputValue?.capacity?.available_date;
        if (capacityDate) {
          return fromUnixTime(capacityDate);
        }
        return null;
      },
    }),
  };
}
