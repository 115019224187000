import { CARD_BORDER, newThemeColors, WHITE } from "ds_legacy/materials/colors";
import { Divider } from "ds_legacy/materials/layouts";
import {
  border,
  dp,
  margin,
  padding,
  responsiveCSS,
} from "ds_legacy/materials/metrics";
import { Title } from "ds_legacy/materials/typography";
import React, { ReactNode } from "react";
import styled, { CSSProperties } from "styled-components";

export const Card = styled.div<{
  backgroundColor?: CSSProperties["backgroundColor"];
  margin?: string;
  noBorder?: boolean;
  position?: string;
  title?: string;
  width?: string;
}>`
  box-sizing: border-box;
  background-color: ${({ backgroundColor: bg }) => bg ?? WHITE};
  position: ${({ position }) => position};
  margin: ${(props) => props.margin || margin(0, 0, 4, 0)};
  max-width: ${({ width }) => width};
  width: 100%;
  border-top: ${({ noBorder, title }) =>
    title || noBorder ? undefined : border({ color: CARD_BORDER })};
`;

export const PaperHeader = styled.div<{
  backgroundColor?: string;
  flexWrap?: boolean;
  ornament?: boolean;
  padding?: string;
}>`
  box-sizing: border-box;
  background-color: ${({ backgroundColor: bg }) =>
    bg ?? newThemeColors.beige[100]};
  border: ${border({ color: CARD_BORDER })};
  padding: ${(props) => props.padding ?? padding(0, 3)};
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? "wrap" : undefined)};
  min-height: ${dp(57)};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media print {
    & {
      height: ${dp(33)};
    }
  }
  ${({ ornament }) =>
    ornament
      ? responsiveCSS({
          mobile: [`flex-direction: column`, `align-items: flex-start`],
        })
      : ""}
`;

export const CardContentContainer = styled.div<{
  minHeight?: string;
  noBorder?: boolean;
  noPadding?: boolean;
  overflowX?: CSSProperties["overflowX"];
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: ${({ minHeight }) => minHeight};
  background-color: ${WHITE};
  flex: 1 0 auto;
  padding: ${({ noPadding, padding: p }) =>
    noPadding ? padding(0) : p || padding(0, 3)};
  border: ${({ noBorder }) =>
    noBorder ? undefined : border({ color: CARD_BORDER })};
  border-top: none;
  width: 100%;
  box-sizing: border-box;
  overflow-x: ${({ overflowX }) => overflowX};
`;

export const CardButtonContainer = styled(CardContentContainer)`
  border: none;
`;

function CardTitle({
  light,
  rightOrnament,
  title,
}: {
  light: boolean;
  rightOrnament: React.ReactNode | undefined;
  title: string;
}) {
  return (
    <PaperHeader ornament={!!rightOrnament}>
      <Title bold light={light} ornament={!!rightOrnament}>
        {title}
      </Title>
      {rightOrnament}
    </PaperHeader>
  );
}

export function InfoCardLite({
  cardMargin,
  children,
  noDivider,
  title,
  width,
}: {
  cardMargin?: string;
  children?: any;
  noDivider?: boolean;
  title?: string;
  width?: string;
}) {
  return (
    <Card margin={cardMargin} noBorder width={width}>
      {title && <Title>{title}</Title>}
      {children}
      {!noDivider && <Divider margin={margin(2, 0, 0, 2)} />}
    </Card>
  );
}

export type ThemeColor = "dark" | "light" | "main";

export default function InfoCard({
  backgroundColor,
  cardMargin,
  children,
  id,
  noBorder,
  position,
  testId,
  themed,
  title,
  titleOrnament,
  width,
}: {
  backgroundColor?: CSSProperties["backgroundColor"];
  cardMargin?: string;
  children?: ReactNode;
  id?: string;
  noBorder?: boolean;
  position?: string;
  testId?: string;
  themed?: ThemeColor;
  title?: string;
  titleOrnament?: React.ReactNode;
  width?: string;
}) {
  return (
    <Card
      backgroundColor={backgroundColor}
      data-testid={testId || id}
      id={id}
      position={position}
      margin={cardMargin}
      width={width}
      title={title}
      noBorder={noBorder}
    >
      {title && (
        <CardTitle
          title={title}
          light={themed === "main" || themed === "dark"}
          rightOrnament={titleOrnament}
        />
      )}
      {children}
    </Card>
  );
}
