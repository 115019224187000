import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  TRACK_EVENTS,
} from "core/consts";
import { useSafeState } from "core/hooks";
import { isTest } from "core/model/utils/featureFlags";
import { computeSealdDisplayId, getSealdSDKInstance } from "core/seald";
import {
  EnvContext,
  PendingSealdAccess,
  QueryProgress,
  SealdAccessPayload,
} from "core/types";
import { useCallback, useEffect, useRef } from "react";
import { useTracking } from "react-tracking";
import { useActivityContext } from "../model/utils/browser/ActivitySingleton";
import { addUsersToGroup } from "./groups";
import { addUsersToSession } from "./sessions";
import { getSealdEntityFromPayload } from "./utils";

export async function handlePendingSealdAccess({
  createSealdAccess,
  envContext,
  pendingSealdAccesses,
}: {
  createSealdAccess: (
    sealdAccess: Partial<SealdAccessPayload>,
  ) => Promise<null>;
  envContext: EnvContext;
  pendingSealdAccesses: PendingSealdAccess[] | null;
}) {
  if (!pendingSealdAccesses?.length) return;

  const pendingGroupAccesses: {
    [groupId in string]: { accountsDisplayIds: string[] };
  } = {};

  for (const pendingSealdAccess of pendingSealdAccesses) {
    if (pendingSealdAccess.account_id) {
      // PSK add account to group - aggregate accounts per group (faster seald transactions)
      const groupId = pendingSealdAccess.seald_encryption_context.seald_id;
      const accountDisplayId = computeSealdDisplayId({
        id: pendingSealdAccess.account_id,
        type: "account",
        envContext,
      });
      const updatedAccountsDisplayIds = [
        ...(pendingGroupAccesses[groupId]?.accountsDisplayIds ?? []),
        accountDisplayId,
      ];
      pendingGroupAccesses[groupId] = {
        accountsDisplayIds: updatedAccountsDisplayIds,
      };
    } else {
      // PSK add group to session
      const [entityType, entityId] =
        getSealdEntityFromPayload(pendingSealdAccess);
      await addUsersToSession({
        entityUsersDisplayIds: [
          computeSealdDisplayId({
            id: entityId,
            type: entityType,
            envContext,
          }),
        ],
        sessionId: pendingSealdAccess.seald_encryption_context.seald_id,
      });
    }
  }

  // PSK add account to group
  for (const [groupId, { accountsDisplayIds }] of Object.entries(
    pendingGroupAccesses,
  )) {
    await addUsersToGroup({
      accountsDisplayIds: accountsDisplayIds,
      createSealdAccess,
      groupId,
    });
  }
}

export function useGetPendingSealdAccess({
  // TODO: study passing the env (or all props) from the seald provider
  createSealdAccess,
  envContext,
  getPendingSealdAccess,
}: {
  createSealdAccess: (
    sealdAccess: Partial<SealdAccessPayload>,
  ) => Promise<null>;
  envContext: EnvContext;
  getPendingSealdAccess: () => Promise<PendingSealdAccess[] | null>;
}) {
  const [manualDone, setManualDone] = useSafeState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const processPendingAccesses = useCallback(async () => {
    try {
      await getSealdSDKInstance();
      const pendingSealdAccesses = await getPendingSealdAccess();
      handlePendingSealdAccess({
        pendingSealdAccesses,
        createSealdAccess,
        envContext,
      });
    } catch (error) {
      if (!isTest) {
        console.error("[processPendingAccesses]", error);
      }
    }
  }, []);

  const getPendingAccesses = useCallback(async () => {
    return new Promise<void>((resolve) => {
      setManualDone(QUERY_PROGRESS_PENDING);
      processPendingAccesses()
        .then(() => setManualDone(QUERY_PROGRESS_SUCCEED))
        .catch(() => setManualDone(QUERY_PROGRESS_FAILED))
        .finally(() => resolve());
    });
  }, [processPendingAccesses, setManualDone]);

  return { manualDone, getPendingAccesses };
}

export function useRestorePendingSealdAccess({
  // TODO: study passing the env (or all props) from the seald provider
  createSealdAccess,
  envContext,
  getPendingSealdAccess,
  isWebSocketAlive,
  useOnPendingSealdAccess,
}: {
  createSealdAccess: (
    sealdAccess: Partial<SealdAccessPayload>,
  ) => Promise<null>;
  envContext: EnvContext;
  getPendingSealdAccess: () => Promise<PendingSealdAccess[] | null>;
  isWebSocketAlive: boolean | undefined;
  useOnPendingSealdAccess: () => readonly [
    PendingSealdAccess[] | undefined,
    boolean,
  ];
}) {
  const { active } = useActivityContext();
  const [pendingSealdAccessesFromSubscription] = useOnPendingSealdAccess();
  const { trackEvent } = useTracking();
  // The "useOnPendingSealdAccess" websocket is triggered exclusively when new pending access requests are generated.
  // For those that exist in the database at the time of loading, we retrieve them to avoid the necessity of waiting for another access request to be created,
  // thereby allowing the retrieval of pending access requests created between visits to the dashboard.
  const didInitialFetch = useRef<boolean>(false);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  // handle WebSocket messages OnPendingSealdAccesses
  useEffect(() => {
    if (pendingSealdAccessesFromSubscription) {
      handlePendingSealdAccess({
        pendingSealdAccesses: pendingSealdAccessesFromSubscription,
        createSealdAccess,
        envContext,
      });
      trackEvent({ name: TRACK_EVENTS.PSA_RESTORED_THROUGH_WS });
    }
  }, [pendingSealdAccessesFromSubscription]);

  // handle polling
  useEffect(() => {
    // when websocket not connected we pull every X minutes
    const intervalToPullInMinutes = active ? 2 : 60;

    async function regenerateSessionKeysFromPending() {
      try {
        await getSealdSDKInstance();
        const pendingSealdAccesses = await getPendingSealdAccess();
        await handlePendingSealdAccess({
          pendingSealdAccesses,
          createSealdAccess,
          envContext,
        });
      } catch (err) {
        console.error(`error handling pending seald accesses`, err);
      }
    }

    // only start polling if not already running and websocket not connected
    if (!intervalRef.current && isWebSocketAlive === false) {
      intervalRef.current = setInterval(
        () => {
          regenerateSessionKeysFromPending();
        },
        intervalToPullInMinutes * 60 * 1000,
      );
    }

    // if polling and websocket is alive clear interval
    if (isWebSocketAlive && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }

    // websocket is only triggered on new accesses being created
    // so we fetch on dashboard mount
    if (!didInitialFetch.current) {
      didInitialFetch.current = true;
      regenerateSessionKeysFromPending();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [active, isWebSocketAlive]);
}
