import { Autocomplete, ListItem, ListItemText, TextField } from "@mui/material";
import { useCareproviderAccountContext } from "context/CareproviderAccountContext";
import {
  QUERY_PROGRESS_PENDING,
  REQUEST_ACTION_SET_RECEIVER_ASSIGNEE,
} from "core/consts";
import { getName, GetNameOptions } from "core/model/accounts";
import { formatFromNow } from "core/model/utils/dates";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Account, AuctionRequest } from "core/types";
import { SelectOption } from "ds_legacy/components/SelectInput";
import { BLACK, WHITE } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, ellipsis, margin, sizing } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_13,
  LINE_HEIGHT_18,
} from "ds_legacy/materials/typography";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import ReceiverAssigneeChange from "dsl/ecosystems/PatientAssessment/ReceiverAssigneeChange";
import { useModal, useRequestAction } from "dsl/hooks";
import { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useLocale, useTranslations } from "translations";

const nameOptions: GetNameOptions = {
  withSalutation: false,
  withAcademicTitle: false,
};

const LastWorkedOnParagraph = styled(Body)`
  line-height: ${LINE_HEIGHT_18};
  ${ellipsis({ maxWidth: dp(300) })}
`;

const ReceiverAssigneeWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 100%;
`;

const useGetReceiverAccounts = () => {
  const { accounts } = useCareproviderAccountContext();
  const getOntology = useGetOntology();

  if (!accounts?.length) return [];

  const careprovidersSelectOptions: SelectOption[] = accounts.map(
    (account) => ({
      id: account?.id,
      label: getName(account, getOntology, nameOptions),
      value: account?.id,
    }),
  );

  return careprovidersSelectOptions;
};

const useOnCompleted = ({
  initialAssignee,
  setOpen,
}: {
  initialAssignee: Account | null | undefined;
  setOpen: (open: boolean) => void;
}) => {
  const assigneeRef = useRef<Account | null | undefined>(initialAssignee);
  const { loggedAccount } = useCareproviderAccountContext();

  return useCallback(
    (newAssignee: Account | null | undefined) => {
      const oldAssignee = assigneeRef?.current;
      const newAssigneeId = newAssignee?.id;

      const assigneeChanged =
        newAssigneeId &&
        Number(oldAssignee?.id) !== newAssigneeId &&
        newAssigneeId !== loggedAccount?.id;

      if (assigneeChanged) setOpen(true);
      if (assigneeRef?.current != null) assigneeRef.current = initialAssignee;
    },
    [assigneeRef, loggedAccount],
  );
};

export const ResponsibleReceiverSelect = ({
  auctionRequest,
  currentValue,
  requestAvailable,
}: {
  auctionRequest: AuctionRequest;
  currentValue: SelectOption | null;
  requestAvailable?: boolean;
}) => {
  const translations = useTranslations();
  const toast = useToast();
  const [open, setOpen] = useModal();
  const options = useGetReceiverAccounts();

  const [selectedAssignee, setSelectedAssignee] = useState<SelectOption>(
    currentValue as SelectOption,
  );

  const [setAssignee, queryProgress] = useRequestAction({
    actionType: REQUEST_ACTION_SET_RECEIVER_ASSIGNEE,
    auctionRequest,
  });

  const onCompleted = useOnCompleted({
    initialAssignee: auctionRequest.assignee,
    setOpen,
  });

  const handleChange = useCallback(
    (data: SelectOption) =>
      setAssignee({
        context: {
          assignee_id: data?.id === -1 ? undefined : data?.id,
        },
        onCompleted: (
          setAssigneeReturnType: AuctionRequest | null | undefined,
        ) => {
          onCompleted(setAssigneeReturnType?.assignee);
        },
        onError: () =>
          toast({
            message: translations.auctionRequest.uploadError,
            color: "danger",
          }),
      }),
    [],
  );

  const onlyAssigneeOptionIsSelected =
    options?.length === 1 && options[0]?.id === selectedAssignee?.id;

  return (
    <>
      <ReceiverAssigneeWrapper data-testid="receiver-assignee">
        <Body
          as="p"
          light={requestAvailable}
          margin={margin(0, 1, 0, 0)}
          lineHeight={LINE_HEIGHT_18}
          whiteSpace="nowrap"
        >
          {translations.patient.responsiblePerson}
          {translations.general.colon}
        </Body>
        {onlyAssigneeOptionIsSelected ? (
          <Body
            fontSize={FONT_SIZE_13}
            color={requestAvailable ? WHITE : undefined}
          >
            &nbsp;{selectedAssignee.label}
          </Body>
        ) : (
          <Autocomplete<SelectOption | null, undefined, true, undefined>
            autoHighlight
            componentsProps={{ paper: { sx: { width: "fit-content" } } }}
            data-testid="assignee-autocomplete"
            defaultValue={currentValue as SelectOption}
            disableClearable
            disabled={
              queryProgress === QUERY_PROGRESS_PENDING ||
              onlyAssigneeOptionIsSelected
            }
            id="assignee-autocomplete"
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, newValue) => {
              handleChange(newValue);
              setSelectedAssignee(newValue);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps }}
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                placeholder={translations.patient.menuAssigneePlaceholder}
                hiddenLabel
                variant="standard"
              />
            )}
            renderOption={(props, option) => {
              return (
                <ListItem
                  divider
                  disablePadding
                  data-testid={`option-${option?.id}`}
                  {...props}
                  key={option?.id}
                >
                  <ListItemText primary={option?.label} />
                </ListItem>
              );
            }}
            sx={{
              color: requestAvailable ? WHITE : undefined,
              minWidth: dp(120),
              "& .MuiAutocomplete-popupIndicator": {
                padding: 0,
                color: requestAvailable ? WHITE : BLACK,
              },
              "& .MuiInputBase-input": {
                fontSize: FONT_SIZE_13,
                color: requestAvailable ? WHITE : BLACK,
                cursor: "pointer",
                "&::placeholder": {
                  opacity: 1,
                },
              },
              "& .MuiAutocomplete-inputRoot": {
                paddingRight: sizing(1),
              },
              "& .MuiInputBase-root": {
                padding: 0,
                paddingTop: "0 !important",
                paddingBottom: "0 !important",
                color: requestAvailable ? WHITE : BLACK,
              },
              "& .MuiAutocomplete-endAdornment": {
                color: requestAvailable ? WHITE : BLACK,
                top: "0 !important",
              },
            }}
            value={selectedAssignee}
          />
        )}
      </ReceiverAssigneeWrapper>
      {open && auctionRequest?.assignee ? (
        <ReceiverAssigneeChange
          assigned={auctionRequest.assignee}
          auctionRequestId={auctionRequest.id}
          nextNotification={() => {
            setOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

export function RightOrnament({
  auctionRequest,
  requestAvailable,
}: {
  auctionRequest: AuctionRequest;
  requestAvailable?: boolean;
}) {
  const getOntology = useGetOntology();
  const translations = useTranslations();
  const locale = useLocale();
  const lastAction = auctionRequest?.last_action;
  const personResponsible =
    lastAction?.account && getName(lastAction?.account, getOntology);
  const timestamp = lastAction?.timestamp && new Date(lastAction?.timestamp);
  const time = timestamp ? formatFromNow(timestamp, locale) : "";

  const currentValue = useMemo(() => {
    return auctionRequest.assignee?.id
      ? {
          id: auctionRequest.assignee.id,
          value: auctionRequest.assignee.id,
          label: getName(auctionRequest.assignee, getOntology, nameOptions),
        }
      : undefined;
  }, [auctionRequest.assignee?.id, nameOptions]);

  return (
    <VerticalLayout margin={margin(1, 2)} maxWidth="100%">
      {time && personResponsible ? (
        <LastWorkedOnParagraph
          as="p"
          light={requestAvailable}
          margin={margin(0)}
        >
          {translations.auctionRequest.lastWorkedOn({
            time,
            personResponsible,
          })}
        </LastWorkedOnParagraph>
      ) : null}
      {auctionRequest ? (
        <ResponsibleReceiverSelect
          currentValue={currentValue || null}
          auctionRequest={auctionRequest}
          requestAvailable={requestAvailable}
        />
      ) : null}
    </VerticalLayout>
  );
}
