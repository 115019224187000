export function getUpdatedRequest() {
  const updatedRequest = window.localStorage.getItem("updatedRequest");
  return updatedRequest ? parseInt(updatedRequest) : undefined;
}

export function setUpdatedRequest(requestId: number) {
  window.localStorage.setItem("updatedRequest", String(requestId));
}

export function clearUpdatedRequest() {
  window.localStorage.removeItem("updatedRequest");
}
