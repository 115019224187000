import { useEnvContext } from "context/EnvContext";
import {
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATUS_INACTIVE,
  APP_VERSION_NAMES,
  CAREPROVIDER_DUPLICATE,
  CAREPROVIDER_INACTIVE,
  CAREPROVIDER_INVALID,
  CAREPROVIDER_TAB_DEFAULT,
  CAREPROVIDER_TRASH,
  REDIRECT_PARAM,
} from "core/consts";
import { Careprovider } from "core/types";
import InactiveProfilePage from "dsl/atoms/InactiveProfilePage";
import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

export const getDefaultRedirectPath = (careproviderId: number) =>
  `/careprovider/dashboard/${careproviderId}/requests/${CAREPROVIDER_TAB_DEFAULT}`;

export const DebugPage = () => (
  <div style={{ flex: "1" }}>{`version: ${process.env.BUILD_VERSION}`}</div>
);

export function generateParams(
  params: Array<[string, number | string | null | undefined]>,
  inQueryParam?: string,
) {
  const queryParam = inQueryParam || "";
  return params.reduce((previous, current) => {
    const [key, value] = current;
    if (value == null) return previous;

    const prefix = previous === "" ? "?" : "&";
    return `${previous}${prefix}${key}=${value}`;
  }, queryParam);
}

export function RequireCondition({
  children,
  condition,
  redirectTo,
  returnNull,
}: {
  children?: JSX.Element | null;
  condition: boolean;
  redirectTo?: string;
  returnNull?: boolean;
}) {
  const { app } = useEnvContext();
  const to =
    redirectTo ?? `/${APP_VERSION_NAMES[app].replace("clinic", "app")}`;

  if (condition) {
    return <>{children ? children : <Outlet />}</>;
  }

  if (returnNull) {
    return null;
  }

  return <Navigate to={to} />;
}

export function ValidateCareprovider({
  careprovider,
  children,
  token,
}: {
  careprovider: Careprovider;
  children: ReactNode;
  token: string | undefined;
}) {
  if (
    careprovider.status == CAREPROVIDER_INACTIVE ||
    careprovider.status == CAREPROVIDER_INVALID ||
    careprovider.status == CAREPROVIDER_TRASH ||
    careprovider.status == CAREPROVIDER_DUPLICATE
  )
    return <InactiveProfilePage careproviderName={careprovider.name} />;

  // validate accounts:
  // if visiting with tokan and there are active accounts
  // or all of them are inactive, redirect to the login page
  const { accounts } = careprovider;
  if (token && accounts) {
    if (
      accounts.some((account) => account.status === ACCOUNT_STATUS_ACTIVE) ||
      accounts.every((account) => account.status === ACCOUNT_STATUS_INACTIVE)
    ) {
      const toAuthWithoutToken = `/careprovider/auth?${REDIRECT_PARAM}=${encodeURIComponent(
        `${location.pathname}`,
      )}&careproviderid=${careprovider.id}`;
      window.location.pathname = toAuthWithoutToken;
      return null;
    }
  }
  return <>{children}</>;
}
