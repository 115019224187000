import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { PREREQUISITES } from "core/consts";
import { GetOntologiesType } from "core/types";
import { getOrderedItems } from "ds_legacy/components/ChipGroup";
import { labelWithOptionalRequiredIcon } from "ds_legacy/components/RequiredIcon";
import ToggleInputField from "ds_legacy/components/ToggleInputField";
import { margin, padding, space } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14, Subheading } from "ds_legacy/materials/typography";
import memoizeOne from "memoize-one";
import { Fragment } from "react";
import {
  FormElement,
  FormElementProps,
  FormWatcher,
  StateDispatcher,
  isValid,
} from "react-forms-state";
import { compose, withProps } from "recompose";
import styled from "styled-components";
import { withTranslations } from "translations";
import Translations from "translations/types";

export type CheckboxGroupProps = {
  elementName?: string;
  exclude?: Array<number>;
  include?: Array<number>;
  items: Array<{
    id: number;
    label: string;
    prerequisite?: boolean;
    serviceId: number;
  }>;
  label: string;
  noMargin?: boolean;
  required?: boolean;
  translations: Translations;
};

const CheckboxesWrapper = styled.div<{ noMargin?: boolean }>`
  display: flex;
  font-size: ${FONT_SIZE_14};
  margin: ${(props) => (props.noMargin ? margin(0) : margin(0, 0, 0, 6))};
`;

export function isChecked(value: any) {
  return value !== false && value != null;
}

const isRequisite = memoizeOne((serviceId) =>
  PREREQUISITES.includes(serviceId),
);

const CheckboxLabel = (label: number | string): JSX.Element => (
  <span style={{ fontSize: FONT_SIZE_14 }}>{label}</span>
);

const CheckboxField = FormElement()(function CheckboxInputField({
  elementName,
  hide,
  label,
  maxWidth = "400px",
  onBeforeChange,
  onChange,
  value,
  width = "100%",
}: FormElementProps & {
  classes?: AnyObject;
  elementName?: string;
  hide?: boolean;
  label: number | string;
  maxWidth?: string;
  onBeforeChange?: (value: number) => any;
  onChange?: (value: number) => void;
  value?: number | string;
  width?: string;
}) {
  if (hide) return null;

  const onChangeHandler = (e: ToType, checked: ToType) => {
    if (onChange && onBeforeChange) {
      onChange(onBeforeChange(checked));
    } else if (onChange) {
      onChange(checked);
    }
  };

  return (
    <>
      <FormControlLabel
        style={{
          maxWidth,
          width,
        }}
        name={String(elementName)}
        control={
          <Checkbox
            color="primary"
            style={{
              height: space(4),
              padding: padding(0, 1.5),
            }}
            checked={isChecked(value)}
            onChange={onChangeHandler}
            value={String(elementName)}
          />
        }
        label={CheckboxLabel(label)}
      />
    </>
  );
});

function CheckboxGroup({
  elementName,
  exclude,
  include,
  items,
  label,
  noMargin,
  required,
  translations,
}: CheckboxGroupProps) {
  const checkboxes = items
    .filter((i) => !i.prerequisite)
    .filter((i) => (include?.length ? include.includes(i.serviceId) : true))
    .map((v) => (
      <Fragment key={`${elementName || "checkbox_group"}-${v.id}`}>
        <CheckboxField
          hide={exclude?.includes(v.serviceId) || false}
          label={v.label}
          elementName={v.id.toString()}
        />

        {isRequisite(v.serviceId) && (
          <FormWatcher watchPath={elementName as string}>
            {({ watchedValue }) => {
              const opened = watchedValue?.[v.serviceId]?.service === true;
              return (
                <div
                  style={{
                    height: !opened ? "0px" : undefined,
                    visibility: opened ? "visible" : "hidden",
                  }}
                >
                  <ToggleInputField
                    label={translations.careproviderProfile.prerequisites}
                    elementName={`${v.serviceId}-prerequisite`}
                  />
                </div>
              );
            }}
          </FormWatcher>
        )}
      </Fragment>
    ));

  return (
    <FormControl component="fieldset">
      <FormWatcher watchPath={elementName as string}>
        {({ watchedValidation }) => (
          <Subheading
            margin={noMargin ? margin(1, 0) : undefined}
            error={!isValid(watchedValidation)}
          >
            {labelWithOptionalRequiredIcon({ label, required })}
          </Subheading>
        )}
      </FormWatcher>
      <CheckboxesWrapper
        noMargin={noMargin}
        data-testid={`checkbox-group-${elementName}`}
      >
        <FormGroup>{checkboxes}</FormGroup>
      </CheckboxesWrapper>
    </FormControl>
  );
}

export const ageServices = [14, 15, 16];

const computeItems = (
  order: AnyObject | undefined,
  getOntologies: GetOntologiesType,
) => {
  {
    let values = getOntologies({ type: "service" });

    values = getOrderedItems(order, values).filter(
      (v) => !ageServices.includes(v.value),
    );

    const items = values.map((item) => ({
      serviceId: item.value,
      id: `${item.value}`,
      label: item.name,
    }));

    const concat = items.concat(
      values
        .filter((v) => isRequisite(v.value))
        .map((item) => ({
          serviceId: item.value,
          id: `${item.value}-prerequisite`,
          label: item.name,
          prerequisite: true,
        })),
    );

    return concat;
  }
};

const computeInValue = (inValue: ToType, items: ToType) => {
  return items.map((v: ToType) => ({
    id: v.id,
    label: v.label,
    serviceId: v.serviceId,
    prerequisite: v.prerequisite || false,
    value: v.prerequisite
      ? inValue?.[v.serviceId]?.prerequisite === true || false
      : inValue?.[v.serviceId]?.service === true || false,
  }));
};

const computeOutValue = (outValue: ToType) => {
  const out = outValue
    .filter((v: ToType) => v.value)
    .reduce(function (output: ToType, item: ToType) {
      (output[item.serviceId] = output[item.serviceId] || []).push(item);
      return output;
    }, {});

  for (const key in out) {
    out[key] = out[key].reduce(
      (obj: AnyObject, s: ToType) =>
        Object.assign(
          obj,
          s.prerequisite ? { prerequisite: true } : { service: true },
        ),
      {},
    );
  }

  return out;
};

const getItems = memoizeOne(computeItems);
const memoizedComputeValue = memoizeOne(computeInValue);

export default compose<
  CheckboxGroupProps,
  Omit<CheckboxGroupProps, "translations" | "items"> & {
    getOntologies: GetOntologiesType;
    order?: AnyObject;
    type: string;
  }
>(
  withTranslations,
  withProps(
    ({
      getOntologies,
      order,
    }: {
      getOntologies: GetOntologiesType;
      order?: AnyObject;
    }) => ({
      items: getItems(order, getOntologies),
    }),
  ),
  StateDispatcher(
    (inValue: Array<ToType> = [], { items }: { items: ToType }) =>
      memoizedComputeValue(inValue, items),
    computeOutValue,
  ),
)(CheckboxGroup);
