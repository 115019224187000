import { BrowserHistory } from "history";
import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { usePagesFetched } from "./usePagesFetched";

export const useScrollRestoreCheckLocation = (
  whiteListRoutes: Array<string>,
) => {
  const { removePersistedData } = usePagesFetched();
  const navigation = useContext(UNSAFE_NavigationContext)
    .navigator as BrowserHistory;

  useEffect(() => {
    return navigation.listen((navigator: any) => {
      const isCurrentLocationNotInWhiteList = whiteListRoutes.every(
        (route) => !navigator.location.pathname.includes(route),
      );
      if (isCurrentLocationNotInWhiteList) {
        removePersistedData();
      }
    });
  }, [navigation]);
};
