import { ApolloClient } from "@apollo/client";
import RenderApp from "containers/Appv4";
import ServiceFactory, { Services } from "containers/ServiceFactory";
import { APP_CAREPROVIDER } from "core/consts";
import { ApolloCacheData } from "core/types";
import { selectAndCheckLoggedCareproviderApollo } from "reduxentities/selectors";
import Routes from "./Router";

export type ReceiverContext = ReturnType<typeof Context>;

const Context = (services: Services) => ({
  app: APP_CAREPROVIDER,
  services,

  checkAuth: (
    careproviderId: number,
    apolloClient: ApolloClient<ApolloCacheData>,
  ): boolean => {
    const careprovider = selectAndCheckLoggedCareproviderApollo(
      services.store.getState(),
      careproviderId,
      apolloClient,
    );

    if (!careprovider) {
      services.logger.info("not authenticated, redirecting...");
      return false;
    } else {
      services.logger.info("authenticated, redirecting...", careprovider);
      return true;
    }
  },
});

const services = ServiceFactory("Careprovider");
const context = Context(services);

RenderApp(context, Routes);
