import React from "react";
import { useFormElementContext, useStateDispatcherContext } from "./Context";
import {
  FormElementContainer,
  FormElementContainerProps,
  InjectedFormElementProps,
} from "./FormElementContainer";
import { WithFormElementProps, _FormElementProps } from "./types";

export function FormElementRenderProps({
  children,
  elementName,
  flatModel,
  root,
  sideMutation,
  validation,
  ...props
}: Omit<
  FormElementContainerProps,
  "formElementContext" | "stateDispatcherContext"
>) {
  const stateDispatcherContext = useStateDispatcherContext();
  const formElementContext = useFormElementContext({ flatModel });

  return (
    <FormElementContainer
      elementName={elementName}
      stateDispatcherContext={stateDispatcherContext}
      formElementContext={formElementContext}
      root={root}
      validation={validation}
      sideMutation={sideMutation}
      {...props}
    >
      {children}
    </FormElementContainer>
  );
}

export function withFormElement<
  T extends WithFormElementProps = WithFormElementProps,
>(WrappedComponent: React.ComponentType<InjectedFormElementProps & T>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithFormElement = ({
    elementName,
    onChange,
    sideMutation,
    validation,
    value,
    ...props
  }: _FormElementProps & Omit<T, keyof InjectedFormElementProps>) => {
    const formElementContext = useFormElementContext();
    const stateDispatcherContext = useStateDispatcherContext();
    const formElementProps = {
      elementName,
      formElementContext,
      onChange,
      sideMutation,
      stateDispatcherContext,
      validation,
      value,
      ...props,
    };
    return (
      <FormElementContainer {...formElementProps}>
        {(formProps) => <WrappedComponent {...(props as T)} {...formProps} />}
      </FormElementContainer>
    );
  };

  ComponentWithFormElement.displayName = `withFormElement(${displayName})`;

  return ComponentWithFormElement;
}

export function FormElement({
  root = false,
}: Omit<WithFormElementProps, keyof _FormElementProps> = {}) {
  return function withFormElement<
    T extends _FormElementProps = _FormElementProps,
  >(WrappedComponent: React.ComponentType<InjectedFormElementProps & T>) {
    // Try to create a nice displayName for React Dev Tools.
    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithFormElement = ({
      elementName,
      flatModel,
      onChange,
      sideMutation,
      validation,
      value,
      ...props
    }: _FormElementProps & Omit<T, keyof InjectedFormElementProps>) => {
      const formElementContext = useFormElementContext({ flatModel });
      const stateDispatcherContext = useStateDispatcherContext();
      const formElementProps = {
        elementName,
        formElementContext,
        onChange,
        root,
        sideMutation,
        stateDispatcherContext,
        validation,
        value,
        ...props,
      };

      return (
        <FormElementContainer {...formElementProps}>
          {(formProps) => <WrappedComponent {...(props as T)} {...formProps} />}
        </FormElementContainer>
      );
    };

    ComponentWithFormElement.displayName = `withFormElement(${displayName})`;

    return ComponentWithFormElement;
  };
}
