import { SxProps, Theme } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ValueOf } from "core/types";
import { KEYBOARD_FOCUS_OUTLINE } from "ds_legacy/materials/colors";
import { border, dp, margin, padding } from "ds_legacy/materials/metrics";
import {
  FONT_FAMILY,
  FONT_SIZE_14,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
} from "ds_legacy/materials/typography";
import { forwardRef, useMemo } from "react";
import LinkV2, { LinkV2Props } from ".";

const LINK_BUTTON_TYPES = {
  default: "default",
  text: "text",
  inverted: "inverted",
} as const;

type LinkButtonType = ValueOf<typeof LINK_BUTTON_TYPES>;

type LinkButtonProps = LinkV2Props & {
  style?: never;
  sx?: SxProps<Theme>;
  type?: LinkButtonType;
};

const DEFAULT_TRANSITION =
  "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";

const baseStyles: SxProps<Theme> = {
  alignItems: "center",
  boxSizing: "border-box",
  cursor: "pointer",
  display: "inline-flex",
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZE_14,
  justifyContent: "center",
  lineHeight: 1.75,
  minHeight: dp(36),
  minWidth: dp(64),
  pointerEvents: "auto",
  position: "relative",
  textAlign: "center",
  textDecoration: "none",
  textTransform: "uppercase",
  userSelect: "none",
  verticalAlign: "middle",
  "&:focus-visible": {
    outline: border({ color: KEYBOARD_FOCUS_OUTLINE, width: 2 }),
  },
};

const getDefaultStyles = (theme: Theme): SxProps<Theme> => ({
  ...baseStyles,
  backgroundColor: theme.palette.primary.main,
  border: 0,
  borderColor: theme.palette.primary.main,
  borderRadius: dp(4),
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px rgba(0,0,0,0.14), 0px 1px 5px rgba(0,0,0,0.12)",
  color: theme.palette.common.white,
  fontWeight: FONT_WEIGHT_REGULAR,
  padding: padding(0.75, 2),
  transition: DEFAULT_TRANSITION,
  width: "fit-content",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
});

const getTextStyles = (theme: Theme): SxProps<Theme> => ({
  ...baseStyles,
  backgroundColor: theme.palette.common.white,
  border: border({ color: "transparent" }),
  borderRadius: dp(4),
  color: theme.palette.primary.main,
  fontWeight: FONT_WEIGHT_MEDIUM,
  margin: margin(1),
  padding: padding(0.875, 1),
  transition: "border 0.1s, color 0.1s, background-color 0.1s",
  width: "100%",
  "&:hover": {
    border: border({ color: theme.palette.primary.main }),
    color: theme.palette.primary.main,
  },
});

const getInvertedStyles = (theme: Theme): SxProps<Theme> => ({
  ...baseStyles,
  backgroundColor: theme.palette.common.white,
  border: border({ color: theme.palette.primary.dark }),
  borderColor: theme.palette.primary.main,
  borderRadius: dp(2),
  color: theme.palette.primary.main,
  fontWeight: FONT_WEIGHT_MEDIUM,
  padding: padding(0.625, 1.875),
  transition: DEFAULT_TRANSITION,
  width: "100%",
  "&:hover": {
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px rgba(0,0,0,0.14), 0px 1px 10px rgba(0,0,0,0.12)",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  "&:active": {
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
});

const styleMap: Record<LinkButtonType, (theme: Theme) => SxProps<Theme>> = {
  [LINK_BUTTON_TYPES.default]: getDefaultStyles,
  [LINK_BUTTON_TYPES.text]: getTextStyles,
  [LINK_BUTTON_TYPES.inverted]: getInvertedStyles,
};

const useGetLinkButtonStyles = (type: LinkButtonType): SxProps<Theme> => {
  const theme = useTheme();
  return useMemo(() => styleMap[type](theme), [theme, type]);
};

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    {
      children,
      type = LINK_BUTTON_TYPES.default,
      sx = {} as SxProps<Theme>,
      ...props
    },
    ref,
  ) => {
    const buttonSx = useGetLinkButtonStyles(type);

    return (
      <LinkV2
        ref={ref}
        underline="none"
        {...props}
        sx={{ ...buttonSx, ...sx } as SxProps<Theme>}
      >
        {children}
      </LinkV2>
    );
  },
);
