import { TRACK_EVENTS } from "core/consts";
import { useCallback, useMemo } from "react";
import { useTracking } from "react-tracking";
import zxcvbn from "zxcvbn";

export const VERY_WEAK = 0;
export const WEAK = 1;
export const MEDIUM = 2;
export const STRONG = 3;
export const VERY_STRONG = 4;

export const MIN = VERY_WEAK;
export const MAX = VERY_STRONG;

type PasswordStrength = {
  strength: number;
  track: () => void;
};

function getReadableStrength(strength: number) {
  switch (strength) {
    case VERY_STRONG:
      return "very_strong";
    case STRONG:
      return "strong";
    case MEDIUM:
      return "medium";
    case WEAK:
      return "weak";
    case VERY_WEAK:
    default:
      return "very_weak";
  }
}

function usePasswordStrength(
  password: string | undefined,
  inputs?: string[],
): PasswordStrength {
  const { trackEvent } = useTracking();

  const passwordStrength = useMemo(
    () => zxcvbn(password ?? "", inputs),
    [password, inputs],
  );

  const track = useCallback(() => {
    trackEvent({
      name: TRACK_EVENTS.PASSWORD_META,
      strength: getReadableStrength(passwordStrength.score),
      time_to_crack:
        passwordStrength.crack_times_display
          .offline_fast_hashing_1e10_per_second,
      guesses: passwordStrength.guesses,
    });
  }, [trackEvent, passwordStrength]);

  return { strength: passwordStrength.score, track };
}

export default usePasswordStrength;
