import { useToastState } from "@react-stately/toast";
import {
  NotificationContentType,
  ToastContentType,
  ToastNotificationRegion,
} from "ds/ui";
import { ReactNode, createContext, useCallback, useContext } from "react";
import { useNotificationState } from "./NotificationReducer";

const TOAST_TIMEOUT = 5000;

const defaultNotificationContext = { toast: () => {}, notify: () => {} };
const NotificationContext = createContext<{
  notify: (options: NotificationContentType) => void;
  toast: (options: ToastContentType) => void;
}>({ toast: () => {}, notify: () => {} });

export function useNotification() {
  const context = useContext(NotificationContext);
  if (!context || context === defaultNotificationContext) {
    throw new Error(
      "useNotification must be used within a ToastNotificationProvider",
    );
  }
  return context.notify;
}

export function useToastContext() {
  const context = useContext(NotificationContext);
  if (!context || context === defaultNotificationContext) {
    throw new Error(
      "useToastContext must be used within a ToastNotificationProvider",
    );
  }
  return context.toast;
}

export function useToast() {
  const toast = useToastContext();

  return (options: ToastContentType) => toast(options);
}

const MAX_VISIBLE_NOTIFICATIONS = 3;
const MAX_VISIBLE_TOASTS = 5;

export function ToastNotificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const notificationState = useNotificationState({
    maxVisibleToasts: MAX_VISIBLE_NOTIFICATIONS,
  });
  const toastState = useToastState<ToastContentType>({
    maxVisibleToasts: MAX_VISIBLE_TOASTS,
  });

  const showToast = useCallback(
    (payload: ToastContentType) => {
      toastState.add(payload, { timeout: TOAST_TIMEOUT });
    },
    [toastState.add],
  );

  const showNotification = useCallback(
    (payload: NotificationContentType) => {
      notificationState.add(payload);
    },
    [notificationState.add],
  );

  const haveNotifications =
    notificationState.visibleToasts.length > 0 ||
    toastState.visibleToasts.length > 0;

  return (
    <NotificationContext.Provider
      value={{ notify: showNotification, toast: showToast }}
    >
      {children}
      {haveNotifications && (
        <ToastNotificationRegion
          notificationState={notificationState}
          toastState={toastState}
        />
      )}
    </NotificationContext.Provider>
  );
}
