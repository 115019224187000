import {
  LOGO_GREY,
  LOGO_LIGHT_BLUE,
  LOGO_MASTER,
  LOGO_MEDIUM_DARK_BLUE,
  LOGO_WHITE,
} from "core/consts";
import { LogoColors } from "core/types";
import { MEDIUM, SMALL, dp, sizing } from "ds_legacy/materials/metrics";
import Image from "dsl/atoms/Image";
import React, { CSSProperties, Suspense } from "react";
import { LinkV2Props } from "../LinkV2";

export type RecareLogoWithNameProps = {
  linkProps?: LinkV2Props;
  noLazyLoad?: boolean;
  size?: string;
  style?: CSSProperties;
  variant?: LogoColors;
};

export type RecareLogoOnlyProps = {
  style?: CSSProperties;
  variant?: Omit<LogoColors, "medium-dark-blue">;
};

export function getRecareLogoWithNamePath(
  variant: RecareLogoWithNameProps["variant"],
) {
  switch (variant) {
    case LOGO_GREY:
      return "logo/svg/recare_logo_rgb_greyscale.svg";
    case LOGO_MASTER:
      return "logo/svg/recare_logo_rgb_master.svg";
    case LOGO_LIGHT_BLUE:
      return "logo/svg/recare_logo_rgb_lightblue_bg.svg";
    case LOGO_MEDIUM_DARK_BLUE:
      return "logo/svg/recare_logo_rgb_mediumdarkblue_bg.svg";
    case LOGO_WHITE:
    default:
      return "logo/svg/recare_logo_rgb_white.svg";
  }
}

export function getRecareLogoOnlyPath(
  variant?: RecareLogoOnlyProps["variant"],
) {
  switch (variant) {
    case LOGO_GREY:
      return "logo/icon-only/regular-usage/recare_picture-mark_rgb_greyscale.svg";
    case LOGO_MASTER:
      return "logo/icon-only/regular-usage/recare_picture-mark_rgb_master.svg";
    case LOGO_LIGHT_BLUE:
      return "logo/icon-only/regular-usage/recare_picture-mark_rgb_lightblue_bg.svg";
    case LOGO_WHITE:
    default:
      return "logo/icon-only/regular-usage/recare_picture-mark_rgb_white.svg";
  }
}

export const getLogoSize = ({ size }: { size?: string }) => {
  switch (size) {
    case SMALL:
      return {
        height: dp(27),
        width: "auto",
      };

    case MEDIUM:
      return {
        height: sizing(4),
        width: "auto",
      };

    default:
      return {
        height: dp(43),
        width: "auto",
      };
  }
};

function Placeholder(props: Partial<RecareLogoWithNameProps>) {
  return (
    <div
      style={{
        ...(props?.style ?? {}),
        ...(props?.size ? getLogoSize({ size: props.size }) : {}),
      }}
    />
  );
}

function Suspend({
  children,
  skip,
}: {
  children: React.ReactNode;
  skip?: boolean;
}) {
  if (skip) return <>{children}</>;

  return (
    <Suspense
      fallback={
        <Placeholder
          {...(React.isValidElement(children) ? children.props : {})}
        />
      }
    >
      {children}
    </Suspense>
  );
}

export function RecareNameLogo({
  linkProps,
  noLazyLoad,
  size,
  style,
  variant,
}: RecareLogoWithNameProps) {
  const { height, width } = getLogoSize({ size });

  return (
    <Suspend skip={noLazyLoad}>
      <Image
        linkProps={linkProps}
        alt="recare-logo"
        testId="recare-logo"
        path={getRecareLogoWithNamePath(variant)}
        style={{
          ...style,
          objectFit: "contain",
          minHeight: height,
          height,
          maxHeight: height,
          width,
        }}
      />
    </Suspend>
  );
}

export function RecareLogoOnly({
  style = { height: dp(32) },
  variant,
}: RecareLogoOnlyProps) {
  return (
    <Suspend>
      <Image
        alt="Logo Recare"
        testId="recare-logo"
        path={getRecareLogoOnlyPath(variant)}
        style={{
          ...style,
        }}
      />
    </Suspend>
  );
}
