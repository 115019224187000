import Avatar from "@mui/material/Avatar";
import {
  ACCOUNT_STATUS_INACTIVE,
  ACCOUNT_TYPE_STAFF,
  ACCOUNT_TYPE_TOOL,
  DEPRECATED_ACCOUNT_TYPES,
  externalUser,
} from "core/consts";
import { useDateLocale } from "core/hooks/useDateLocale";
import { getInitialsForAccount, getName } from "core/model/accounts";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import {
  Careprovider,
  Careseeker,
  Event as EventType,
  GetOntologyType,
} from "core/types";
import { format, formatRelative, fromUnixTime } from "date-fns";
import {
  ACCENT_DARK_COLOR,
  CARD_BACKGROUND,
  FAB_DEFAULT_BACKGROUND,
  ICON_DARK,
  PRIMARY_DARK_COLOR,
  PROVIDER_PRIMARY_DARK_COLOR,
  TEXT_ACCENT_DARK,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { border, dp, margin, padding } from "ds_legacy/materials/metrics";
import { SkeletonCircle, SkeletonRectangle } from "ds_legacy/materials/shapes";
import {
  Body,
  Caption,
  FONT_SIZE_16,
  EventChip as Tooltip,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { EventSpecMessage } from "dsl/atoms/EventSpecs";
import { MailIcon } from "lucide-react";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import Translations from "translations/types";

export function TimelineEventCard({
  event,
  forFhir,
  forIsik,
  icon,
  title,
}: {
  event: EventType;
  forFhir?: boolean;
  forIsik?: boolean;
  icon?: any;
  title: string;
}) {
  const print = usePrint();
  const { currentLocale: locale } = useDateLocale();

  if (!print)
    return (
      <HorizontalLayout margin={margin(2, 0)} flexShrink={1}>
        {forFhir ? null : (
          <HorizontalLayout width={dp(45)}>
            <Avatar
              sx={{
                backgroundColor: WHITE,
                color: ICON_DARK,
                border: border({ color: ICON_DARK }),
                width: 40,
                height: 40,
              }}
            >
              {icon || (event.account && getInitialsForAccount(event.account))}
            </Avatar>
          </HorizontalLayout>
        )}
        <VerticalLayout flexShrink={1}>
          <Body>{title}</Body>
          <HorizontalLayout>
            <Caption>
              {event.timestamp &&
                (forIsik
                  ? format(fromUnixTime(event.timestamp), "PPpp", { locale })
                  : formatRelative(fromUnixTime(event.timestamp), new Date(), {
                      locale,
                    }))}
            </Caption>
          </HorizontalLayout>
        </VerticalLayout>
      </HorizontalLayout>
    );

  return (
    <>
      <td>
        <Caption>
          {event.timestamp &&
            format(fromUnixTime(event.timestamp), "PPpp", { locale })}
        </Caption>
      </td>
      <td>
        <Body>{title}</Body>
      </td>
    </>
  );
}

const EventChip = styled.span<{ chipMargin?: string; chipPadding?: string }>`
  display: flex;
  align-items: center;
  border-radius: ${dp(7)};
  padding: ${({ chipPadding }) => chipPadding || padding(0.7, 1)};
  margin: ${(props) => props.chipMargin || margin(0, 1, 0, 0)};
  background-color: ${(props) => props.color || FAB_DEFAULT_BACKGROUND};
`;

export const Chip = ({
  chipMargin,
  chipPadding,
  color,
  label,
  maxCharacters,
}: {
  chipMargin?: string;
  chipPadding?: string;
  color?: string;
  label: string;
  maxCharacters?: number;
}) => (
  <EventChip color={color} chipMargin={chipMargin} chipPadding={chipPadding}>
    <Tooltip>
      {maxCharacters && label.length > maxCharacters
        ? `${label.substring(0, maxCharacters - 3)}...`
        : label}
    </Tooltip>
  </EventChip>
);

export const AccountChip = ({
  accountName,
  accountType,
}: {
  accountName?: string;
  accountType: number | undefined;
}) => {
  if (externalUser(accountType))
    return <Chip label={accountName || "External "} color={TEXT_ACCENT_DARK} />;

  switch (accountType) {
    case ACCOUNT_TYPE_STAFF:
      return <Chip label={accountName || "Recare Staff"} color="#C51162" />;
    case ACCOUNT_TYPE_TOOL:
      return <Chip label="Tool" color={CARD_BACKGROUND} />;
    default:
      if (!DEPRECATED_ACCOUNT_TYPES.includes(accountType || -1))
        console.error("Could not find account type ", accountType);
      return null;
  }
};

export function stringToHex(str: string) {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
  }

  const c = (hash & 0x00ffffff).toString(16).toUpperCase(); // eslint-disable-line no-bitwise
  return `#${"00000".substring(0, 6 - c.length)}${c}`;
}

const WithLink = ({
  children,
  link,
}: {
  children: React.ReactNode;
  link?: string;
}) =>
  link ? (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  );

function TimelineSpecMessage({
  eventMessage,
  textAlign,
  whiteSpace,
}: {
  eventMessage: Exclude<EventSpecMessage, string> | undefined;
  textAlign?: CSSProperties["textAlign"];
  whiteSpace?: CSSProperties["whiteSpace"];
}) {
  if (!eventMessage || typeof eventMessage === "string") return null;

  return (
    <>
      {eventMessage.title && (
        <Body margin={margin(0)} textAlign={textAlign} whiteSpace="pre-wrap">
          {eventMessage.title}
        </Body>
      )}
      {eventMessage.date && (
        <Caption
          margin={margin(0, 1.25, 0, 1.25)}
          textAlign={textAlign}
          whiteSpace={whiteSpace}
        >
          {eventMessage.date}
        </Caption>
      )}
      {eventMessage.date && eventMessage.time && (
        <Caption
          margin={margin(0, 1.25, 0, 1.25)}
          textAlign={textAlign}
          whiteSpace={whiteSpace}
        >
          {eventMessage.time}
        </Caption>
      )}
    </>
  );
}

function AcpChip({
  chips,
  event,
  eventMessages,
  Icon,
  message,
  title,
}: {
  Icon?: any;
  chips?: any;
  event: EventType;
  eventMessages?: EventSpecMessage[];
  message?: JSX.Element | string | null;
  title?: JSX.Element | string;
}) {
  const { currentLocale: locale } = useDateLocale();

  return (
    <HorizontalLayout margin={margin(1, 0)} aligned flexShrink={1}>
      {Icon ? (
        <div style={{ margin: "27px 0px 0px 0px" }}>
          <Icon
            style={{ color: ICON_DARK, fontSize: dp(16) }}
            size={FONT_SIZE_16}
          />
        </div>
      ) : undefined}
      <VerticalLayout
        margin={Icon ? undefined : margin(0, 0, 0, 1.7)}
        flexShrink={1}
        stretched
      >
        <HorizontalLayout aligned>
          <Caption>
            {event.timestamp &&
              formatRelative(fromUnixTime(event.timestamp), new Date(), {
                locale,
              })}
          </Caption>
          <HorizontalLayout stretched justify="flex-end">
            {chips}
          </HorizontalLayout>
        </HorizontalLayout>
        {title && <Body>{title}</Body>}
        {message && <Caption whiteSpace="normal">{message}</Caption>}
        {eventMessages?.map((msg, idx) => (
          <TimelineSpecMessage key={idx.toString()} eventMessage={msg} />
        ))}
      </VerticalLayout>
    </HorizontalLayout>
  );
}

export function TimelineAuctionEventCard({
  careprovider,
  careseeker,
  event,
  eventMessages,
  message,
  title,
}: {
  careprovider?: Careprovider;
  careseeker?: Careseeker;
  event: EventType;
  eventMessages?: EventSpecMessage[];
  message?: JSX.Element | string | null;
  title: JSX.Element | string;
}) {
  const getOntology = useGetOntology();
  const getEventMessage = () => {
    if (message) return message;

    if (!event.context?.message) return "";

    if (event.context.message_iv != null) return "";

    return event.context.message;
  };

  const eventMessage = getEventMessage();

  const chips = [];

  if (careseeker?.name)
    chips.push(
      <Chip
        key="careseeker_name"
        color={PRIMARY_DARK_COLOR}
        label={careseeker.name}
        maxCharacters={70}
      />,
    );
  else if (careprovider?.name) {
    chips.push(
      <Chip
        key="careprovider_name"
        color={PROVIDER_PRIMARY_DARK_COLOR}
        label={careprovider.name}
        maxCharacters={70}
      />,
    );
  }

  if (event.account?.first_name && getName(event.account, getOntology))
    chips.push(
      <Chip
        key="account_name"
        color={ACCENT_DARK_COLOR}
        label={getName(event.account, getOntology)}
      />,
    );

  if (event.context?.user_id) {
    if (event.context?.request_link)
      chips.push(
        <WithLink key="user_id" link={event.context.request_link}>
          <Chip label={event.context.user_id} />
        </WithLink>,
      );
    // For the patient event - no request link.
    else if (event.patient_id)
      chips.push(
        <WithLink
          key="user_id"
          link={`https://app.recaresolutions.com/app/clinic/dashboard/patient/${event.patient_id}`}
        >
          <Chip label={event.context.user_id} />
        </WithLink>,
      );
  }

  return (
    <AcpChip
      title={title}
      event={event}
      message={eventMessage}
      eventMessages={eventMessages}
      chips={chips}
    />
  );
}

export function TimelineEventCardCareprovider({
  event,
  eventMessages,
  Icon,
  message,
  title,
}: {
  Icon?: any;
  event: EventType;
  eventMessages?: EventSpecMessage[];
  message?: JSX.Element | string | null;
  title: JSX.Element | string;
}) {
  const getOntology = useGetOntology();

  const chips = [];

  if (event.account)
    chips.push(
      <AccountChip
        key="account"
        accountName={getName(event.account, getOntology)}
        accountType={event?.account?.account_type}
      />,
    );

  if (event.careseeker?.name)
    chips.push(
      <Chip
        key="careseeker_name"
        label={event.careseeker.name}
        color={TEXT_ACCENT_DARK}
      />,
    );
  return (
    <AcpChip
      title={title}
      event={event}
      Icon={Icon}
      message={message}
      chips={chips}
      eventMessages={eventMessages}
    />
  );
}

const SkeletonElement = styled.div`
  display: flex;
  height: 77px;
  flex-shrink: 1;
`;

export function SkeletonCard() {
  return (
    <SkeletonElement>
      <SkeletonCircle />
      <VerticalLayout flexShrink={1} margin={margin(1, 2)}>
        <SkeletonRectangle width="80px" height="10px" />
        <HorizontalLayout margin={margin(1, 0)}>
          <SkeletonRectangle width="30px" height="10px" />
        </HorizontalLayout>
      </VerticalLayout>
    </SkeletonElement>
  );
}

const StatusComparison = ({
  event,
  target,
  text,
}: {
  event: EventType;
  target:
    | "accountStatus"
    | "careproviderStatus"
    | "careseekerStatus"
    | "onboardingStatus";
  text: (status: { newStatus: string; prevStatus: string }) => string;
}) => {
  const getOntology = useGetOntology();
  const getStatus = (status: number) =>
    getOntology({ type: target, key: status });

  const prevStatus =
    (event.context?.previous_status != null &&
      getStatus(event.context.previous_status)) ||
    "";
  const newStatus =
    (event.context?.new_status != null &&
      getStatus(event.context.new_status)) ||
    "";

  return (
    <TranslationComposition
      translations={text({ prevStatus, newStatus })}
      withOptions
    >
      {([before, previous, to, next, after]) => (
        <>
          {before}
          <b>{previous}</b>
          {to}
          <b>{next}</b>
          {after}
        </>
      )}
    </TranslationComposition>
  );
};

export const CareproviderStatusChanged = ({
  event,
  translations,
}: {
  event: EventType;
  translations: Translations;
}) => (
  <TimelineEventCardCareprovider
    event={event}
    title={
      <StatusComparison
        target="careproviderStatus"
        event={event}
        text={translations.timeline.careproviderStatusChanged}
      />
    }
  />
);

export const AccountStatusChanged = ({
  event,
  translations,
}: {
  event: EventType;
  translations: Translations;
}) => {
  let title: string | JSX.Element = "";

  if (event.context?.new_status === ACCOUNT_STATUS_INACTIVE) {
    title = translations.acp.accountTimeline.accountDeactivated;
  } else if (event.context?.previous_status === ACCOUNT_STATUS_INACTIVE) {
    title = translations.acp.accountTimeline.accountReactivated;
  } else {
    title = (
      <StatusComparison
        target="accountStatus"
        event={event}
        text={translations.timeline.statusChanged}
      />
    );
  }

  return <TimelineEventCardCareprovider event={event} title={title} />;
};

export const OnboardingStatusChanged = ({
  event,
  translations,
}: {
  event: EventType;
  translations: Translations;
}) => (
  <TimelineEventCardCareprovider
    event={event}
    message={event.context?.onboarding_comment}
    title={
      <StatusComparison
        target="onboardingStatus"
        event={event}
        text={translations.timeline.careproviderOnboardingStatusChanged}
      />
    }
  />
);

export const CareseekerStatusChanged = ({
  event,
  translations,
}: {
  event: EventType;
  translations: Translations;
}) => (
  <TimelineAuctionEventCard
    event={event}
    title={
      <StatusComparison
        target="careseekerStatus"
        event={event}
        text={translations.timeline.statusChanged}
      />
    }
  />
);

export const EmailSent = ({
  event,
  forCareprovider,
}: {
  event: EventType;
  forCareprovider?: boolean;
}) => {
  const getOntology = useGetOntology();
  if (!forCareprovider || !event.context) return null;

  const emailType = getOntology({
    type: "emailType",
    key: event.context.mail_type,
  });

  const recipients = event.context?.recipients?.join(", ");

  return (
    <AcpChip
      event={event}
      Icon={MailIcon}
      message={recipients}
      chips={[
        <Chip
          key="emailtype"
          label={emailType || (event.context.mail_type || "").toString()}
        />,
      ]}
    />
  );
};

const getRoles = (event: EventType, getOntology: GetOntologyType) =>
  event.context?.new_roles
    ?.map((key) => getOntology({ type: "accountRoleShort", key }))
    .join(", ") || "";

export function EventAccountPermissions({ event }: { event: EventType }) {
  const translations = useTranslations();
  const getOntology = useGetOntology();

  const destination = event.subject_account
    ? `${event.subject_account.first_name || ""} ${
        event.subject_account.last_name || ""
      } - `
    : "";

  if (event.context?.careprovider_name)
    return (
      <TimelineEventCardCareprovider
        title={`${destination}${translations.acp.accountTimeline.permissionsUpdated(
          {
            resource: `${event.context.careprovider_name} ${getRoles(
              event,
              getOntology,
            )}`,
          },
        )}`}
        event={event}
      />
    );
  if (event.careseeker?.name)
    return (
      <TimelineEventCardCareprovider
        title={`${destination}${translations.acp.accountTimeline.permissionsUpdated(
          {
            resource: `${event.careseeker.name} ${getRoles(
              event,
              getOntology,
            )}`,
          },
        )}`}
        event={event}
      />
    );

  return (
    <TimelineEventCardCareprovider
      title={`${destination}${translations.acp.accountTimeline.permissionsUpdated(
        {
          resource: getRoles(event, getOntology),
        },
      )}`}
      event={event}
    />
  );
}
