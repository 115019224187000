import { getStaticAsset } from "core/model/config";
import LinkV2, { LinkV2Props } from "ds_legacy/components/LinkV2";
import React, { forwardRef } from "react";

type BaseImageProps = {
  alt: string;
  linkProps?: LinkV2Props;
  style?: React.CSSProperties;
  testId?: string;
  width?: React.CSSProperties["width"];
};

type ImagePropsWithSrc = BaseImageProps & {
  path?: never;
  src: string;
};

type ImagePropsWithPath = BaseImageProps & {
  path: string;
  src?: never;
};

export const Image = forwardRef<
  HTMLImageElement,
  ImagePropsWithSrc | ImagePropsWithPath
>(({ alt, linkProps, path, src, style, testId, width }, ref) => {
  const imageUrl = src ? src : getStaticAsset(path as string);

  const Img = ({ imageTestId }: { imageTestId?: string }) => (
    <img
      alt={alt}
      data-testid={imageTestId}
      ref={ref}
      src={imageUrl}
      style={{ ...style, objectFit: "contain" }}
      width={width}
    />
  );

  if (linkProps) {
    return (
      <LinkV2 data-testid={testId} {...linkProps}>
        <Img />
      </LinkV2>
    );
  }

  return <Img imageTestId={testId} />;
});

export default Image;
