import { ApolloClient } from "@apollo/client";
import { GetAccountQueryHOC } from "apollo/queries";
import {
  isCareseekerLogged,
  isProviderSearchLogged,
} from "core/model/accounts";
import Config, {
  ENV_DEMO,
  ENV_DEVELOPMENT,
  ENV_PREPROD,
  ENV_PRODUCTION,
  ENV_STAGING,
} from "core/model/config";
import {
  Account,
  AccountInIdentification,
  ApolloCacheData,
  CareproviderRoles,
  CareseekerInRole,
  CareseekerRoles,
  Country,
  QueryProgress,
  Roles,
  Settings,
  SupportedLanguage,
} from "core/types";
import gql from "graphql-tag";
import { ComponentType, ReactNode } from "react";
import { connect, useSelector } from "react-redux";
import { branch, compose, mapProps } from "recompose";
import { createSelector } from "reselect";

export type StateIdentification = {
  account: AccountInIdentification;
  admin_roles: Array<number>;
  careprovider_roles: Array<CareproviderRoles>;
  careseeker_roles: Array<CareseekerRoles>;
  privateKey: string;
};

export type StateAuth = {
  active_roles: number[] | undefined;
  careprovider?: number;
  careprovider_name?: string;
  careseeker?: number;
  careseeker_name?: string;
  country?: Country;
  credentials?: {
    auth_type: "password" | "sso";
    expiration: number;
    token: string;
    token_type: string;
  };
  identification?: StateIdentification;
  version: string;
};

export type State = {
  auth?: StateAuth;
  settings?: Settings;
};

export const selectToken = (state: State) => state.auth?.credentials?.token;

export const selectTokenType = (state: State) =>
  state.auth?.credentials?.token_type;

export const selectVersion = (state: State) => state.auth?.version;

export const selectCredentials = (state: State) => state.auth?.credentials;

export const selectCareproviderRoles = (state: State) =>
  state.auth?.identification?.careprovider_roles;

export const selectCareseekerRoles = (state: State) =>
  state.auth?.identification?.careseeker_roles;

function careproviderFromCache(
  apolloClient: ApolloClient<ApolloCacheData>,
  careproviderId: number,
) {
  return apolloClient.readFragment({
    id: `Careprovider:${careproviderId}`,
    fragment: gql`
      fragment loggedProvider on Careprovider {
        id
      }
    `,
  });
}

export const selectLoggedCareproviderId = (state: State): number | undefined =>
  state.auth?.identification ? state.auth.careprovider : undefined;

export const selectAndCheckLoggedCareproviderApollo = (
  state: State,
  careproviderId: number,
  apolloClient: ApolloClient<ApolloCacheData>,
) => {
  const cachedProvider = careproviderFromCache(apolloClient, careproviderId);
  if (!cachedProvider) return false;

  const loggedCareproviderId = selectLoggedCareproviderId(state);
  if (!loggedCareproviderId) return false;

  return cachedProvider.id == loggedCareproviderId;
};

export const selectLoggedInAccountId = (state: State) =>
  state.auth?.identification?.account?.id;

export const selectLoggedCareprovider = (
  state: State,
  apolloClient: ApolloClient<ApolloCacheData>,
) => {
  const loggedCareproviderId = selectLoggedCareproviderId(state);

  if (!loggedCareproviderId) return null;

  const careprovider = careproviderFromCache(
    apolloClient,
    loggedCareproviderId,
  );

  if (!careprovider) return null;

  return careprovider;
};

export const selectCareseekers = createSelector(
  selectCareseekerRoles,
  (roles) => {
    if (!roles?.length) return undefined;

    return roles.reduce<CareseekerInRole[]>((acc, curr) => {
      if (curr?.careseeker) {
        return [...acc, curr.careseeker];
      }
      return acc;
    }, []);
  },
);

export const selectLoggedCareseeker = (state: State) => {
  const loggedCareseekerId = state.auth?.careseeker;
  if (!loggedCareseekerId) return null;

  const careseekers = selectCareseekers(state);

  if (!careseekers) return null;

  return careseekers.find(
    (careseeker) => careseeker?.id === loggedCareseekerId,
  );
};

export const selectAuthType = (state: State) =>
  state.auth?.credentials?.auth_type;

export const selectActiveRoles = (state: State) => state.auth?.active_roles;

export const selectIdentification = (state: State) =>
  state.auth?.identification;

export function useLoggedInAccount() {
  return useSelector((state: State) => state.auth?.identification?.account);
}

export const selectPrivateKey = (state: State): string | undefined =>
  state.auth?.identification?.privateKey;

export const usePrivateKey = () => useSelector(selectPrivateKey);

// This hardcodes the ID of the default careprovider id per env.
export function getDefaultProviderAccountId() {
  switch (Config.environment) {
    case ENV_PRODUCTION:
      return 35;
    case ENV_PREPROD:
    case ENV_DEMO:
      return 14;
    case ENV_STAGING:
    case ENV_DEVELOPMENT:
    default:
      return 3;
  }
}

type WithLoggedAccountInjectedProps = {
  account: Account | null;
  accountId: number | undefined;
  getAccountQueryProgress: QueryProgress;
};
type WithLoggedAccountHocProps<T> = Omit<
  T,
  keyof WithLoggedAccountInjectedProps
>;

export const ConnecterWithLoggedAccount = <T extends AnyObject>(
  Component: ComponentType<T>,
): ComponentType<WithLoggedAccountHocProps<T>> => {
  return compose<T, WithLoggedAccountHocProps<T>>(
    connect((state: State) => ({
      accountId: selectLoggedInAccountId(state),
    })),
    branch(
      ({ accountId }: { accountId: number }) => accountId != undefined,
      compose(
        GetAccountQueryHOC,
        mapProps(
          ({
            queryProgress,
            ...props
          }: {
            account: Account | null;
            queryProgress: QueryProgress;
          }) => ({
            getAccountQueryProgress: queryProgress,
            ...props,
          }),
        ),
      ),
    ),
  )(Component);
};

export type WithPrivateKeyInjectedProps = {
  activeRoles: Roles | undefined;
  careseeker: CareseekerInRole | null | undefined;
  careseekerId: number | undefined;
  privateKey: string | undefined;
};
export type WithPrivateKeyHocProps<T> = Omit<
  T,
  keyof WithPrivateKeyInjectedProps
>;

export const ConnecterWithPrivateKey = <T extends AnyObject>(
  Component: ComponentType<T>,
): ComponentType<WithPrivateKeyHocProps<T>> => {
  return compose<T, WithPrivateKeyHocProps<T>>(
    ConnecterWithLoggedAccount,
    connect((state: State) => ({
      privateKey: selectPrivateKey(state),
    })),
  )(Component);
};

export const ConnecterWithQueryToken = connect((state: State) => ({
  token: selectTokenType(state) === "query" && selectToken(state),
}));

export const ConnecterWithLoggedCareprovider = connect((state: State) => ({
  careproviderId: selectLoggedCareproviderId(state),
}));

export const ConnecterWithJwtToken = connect((state: State) => ({
  token: selectTokenType(state) === "jwt" && selectToken(state),
  account: selectLoggedInAccountId(state),
}));

const ConnecterWithCareproviderRoles = connect((state: State) => ({
  roles: selectCareproviderRoles(state),
}));

export const ConnecterWithCareproviderSwitcher = connect(
  () => ({}),
  (dispatch) => ({
    careproviderChanged: (careproviderId: number) => {
      dispatch({
        type: "CAREPROVIDER_CHANGED",
        payload: { careproviderId },
      });
    },
  }),
);

export const ConnectedProviderJWT = compose(
  ConnecterWithLoggedCareprovider,
  ConnecterWithJwtToken,
  ConnecterWithCareproviderRoles,
  ConnecterWithCareproviderSwitcher,
  ConnecterWithLoggedAccount,
)(
  // @ts-expect-error
  ({
    account,
    careproviderChanged,
    careproviderId,
    children,
    roles,
    token,
  }: {
    account: Account;
    careproviderChanged: (careproviderId: number) => void;
    careproviderId: number;
    children: (args: {
      account: Account;
      careproviderChanged: (careproviderId: number) => void;
      careproviderId: number;
      roles: Roles;
      token: string;
    }) => ReactNode;
    roles: Roles;
    token: string;
  }) =>
    children({ careproviderId, token, roles, careproviderChanged, account }),
);

export function useIsLoggedOnClinicApp() {
  return useSelector(isCareseekerLogged);
}

export function useIsLoggedOnProviderSearchApp() {
  return useSelector(isProviderSearchLogged);
}

export const ConnectedCareprovider = connect((state: State) => ({
  connected:
    selectToken(state) != null &&
    selectTokenType(state) === "jwt" &&
    selectLoggedInAccountId(state) != null,
}));

export type WithCareseekerInjectedProps = {
  activeRoles: Roles | undefined;
  careseeker: CareseekerInRole | null | undefined;
  careseekerId: number | undefined;
  privateKey: string | undefined;
};
export type WithCareseekerHocProps<T> = Omit<
  T,
  keyof WithCareseekerInjectedProps
>;

export const getCareseeker = connect((state: State) => {
  const careseeker = selectLoggedCareseeker(state);
  return {
    careseekerId: careseeker?.id,
    careseeker,
  };
});

export function useLocale(): SupportedLanguage {
  return useSelector((state: State) => state.settings?.language ?? "de");
}
