import { TableSortLabel, Tooltip } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from "core/consts";
import { SortOrder, TourElement } from "core/types";
import { SpinnerPage } from "ds_legacy/components/Spinner";
import {
  BORDER_BOTTOM,
  ICON_DARK,
  PRIMARY_COLOR,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  border,
  dp,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import { FONT_SIZE_12, FONT_SIZE_16 } from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { InfoIcon } from "lucide-react";
import { DependencyList, MouseEvent, ReactNode } from "react";
import styled from "styled-components";
import Translations from "translations/types";
import { ColumnKinds, GeneralTableColumn } from "./CustomCells";
import { ActionMenuOption, GeneralListActionKind } from "./MenuActions";
import {
  StyledGeneralTable,
  TableIntroSubtitle,
  TableIntroTitle,
} from "./styles";
import { convertAlignToJustifyContent, useGetTableBody } from "./utils";

export type GeneralTableActions<
  Data extends { id: number | string },
  RowActions extends GeneralListActionKind[],
> = {
  allowList?: (data: Data) => RowActions;
  getOptions: (props: {
    data: Data;
    translations: Translations;
  }) => ActionMenuOption<RowActions>[] | (() => ActionMenuOption<RowActions>[]);
  getTestId?: (data: Data) => string;
  onActionMenuClick?: (e: MouseEvent<HTMLButtonElement>, data: Data) => void;
  tour?: (rowNumber: number) => TourElement | undefined;
  width?: string;
};

export type GeneralTableProps<
  Data extends { id: number | string },
  RowActions extends GeneralListActionKind[],
> = {
  actions?: GeneralTableActions<Data, RowActions>;
  columns: GeneralTableColumn<NonNullable<Data>, ColumnKinds>[];
  data: readonly Data[];
  dependencies?: DependencyList;
  emptyComponent: ReactNode | null;
  getA11yRowProps?: (props: { data: Data; index: number }) => AnyObject;
  id: string;
  isLoading?: boolean;
  isMessenger?: boolean;
  isSelected?: (id: Data["id"]) => boolean;
  onRowClick?: (props: {
    event: MouseEvent<HTMLElement>;
    rowData: Data;
  }) => void;
  rowClickDisabled?: (props: { data: Data; index: number }) => boolean;
  setOrder?: (sortOrder: SortOrder) => void;
  setOrderBy?: (sortBy: string) => void;
  sortBy?: string;
  sortOrder?: SortOrder;
  tour?: TourElement;
};

function Loading({ delay = 350, id }: { delay?: number; id: string }) {
  return (
    <HorizontalLayout
      margin="0 auto"
      padding={padding(4, 0)}
      minHeight={dp(150)}
      justify="center"
      maxWidth="100%"
      aligned
    >
      <SpinnerPage id={`${id}-spinner`} delay={delay} />
    </HorizontalLayout>
  );
}

export const StyledInnerCellWrapper = styled(HorizontalLayout)<{
  disableDivider?: boolean;
  isTransparentDivider?: boolean;
}>`
  overflow: visible;
  position: relative;
  width: 100%;

  ${({ disableDivider, isTransparentDivider }) =>
    disableDivider
      ? ""
      : `
  padding-left: ${dp(14)};
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: ${dp(14)};
    border-left: ${border({
      width: 2,
      color: isTransparentDivider ? "transparent" : BORDER_BOTTOM,
    })};
    width: ${dp(14)};
    `}
`;

export function TableIntro({
  icon,
  leftAction,
  rightAction,
  subtitle,
  testId,
  title,
  tour,
}: {
  icon?: JSX.Element;
  leftAction?: JSX.Element;
  rightAction?: JSX.Element;
  subtitle?: string;
  testId?: string;
  title?: string;
  tour?: TourElement;
}) {
  const print = usePrint();
  if (!icon && !subtitle && !title) return null;

  if (print)
    return (
      <VerticalLayout width="100%">
        {title ? (
          <TableIntroTitle
            style={{ padding: padding(0, 1), fontSize: FONT_SIZE_12 }}
          >
            {title}
          </TableIntroTitle>
        ) : null}
        {subtitle ? <TableIntroSubtitle>{subtitle}</TableIntroSubtitle> : null}
      </VerticalLayout>
    );

  return (
    <VerticalLayout
      data-testid={testId ?? "table-intro"}
      gap={dp(5)}
      padding={padding(2, 0)}
    >
      <HorizontalLayout overflow="visible" gap={dp(6)} aligned>
        {icon ? (
          <VerticalLayout
            aligned
            style={{
              width: dp(30),
              color: PRIMARY_COLOR,
            }}
          >
            {icon}
          </VerticalLayout>
        ) : null}
        {title ? <TableIntroTitle {...tour}>{title}</TableIntroTitle> : null}
        {leftAction ?? null}
        <div
          style={{
            marginLeft: "auto",
            marginRight: sizing(1),
          }}
        >
          {rightAction ?? null}
        </div>
      </HorizontalLayout>
      {subtitle ? (
        <TableIntroSubtitle icon={!!icon}>{subtitle}</TableIntroSubtitle>
      ) : null}
    </VerticalLayout>
  );
}

function EnhancedTableHead<Data>({
  onRequestSort,
  order,
  orderBy,
  visibleColumns,
}: {
  onRequestSort: (property: string) => void;
  order?: SortOrder;
  orderBy?: string;
  visibleColumns: GeneralTableColumn<NonNullable<Data>, ColumnKinds>[];
}) {
  const print = usePrint();

  return (
    <TableHead>
      <TableRow>
        {visibleColumns.map(
          (
            { align, disableDivider, hint, sortBy, title, tour, width },
            index,
          ) => {
            disableDivider = index === 0 || disableDivider;

            return (
              <TableCell align={align} key={index} sx={{ width }}>
                <StyledInnerCellWrapper
                  disableDivider={disableDivider}
                  {...tour}
                  style={{
                    textAlign: align,
                    justifyContent: convertAlignToJustifyContent(align),
                    flex: 1,
                  }}
                >
                  {sortBy ? (
                    <TableSortLabel
                      active={orderBy === sortBy}
                      direction={orderBy === sortBy ? order : SORT_ORDER_ASC}
                      onClick={() => onRequestSort(sortBy)}
                      sx={{
                        flexDirection:
                          align === "right" ? "row-reverse" : undefined,
                        textAlign: align,
                        "& .MuiTableSortLabel-icon": {
                          marginRight: align === "right" ? undefined : 0,
                        },
                      }}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    <span style={{ textAlign: align }}>{title}</span>
                  )}
                  {hint && !print && (
                    <HorizontalLayout aligned width="100%">
                      <Tooltip title={hint}>
                        <InfoIcon
                          style={{
                            color: ICON_DARK,
                            fontSize: FONT_SIZE_16,
                            margin: margin(0, 0, 0, 1.5),
                          }}
                          size={FONT_SIZE_16}
                        />
                      </Tooltip>
                    </HorizontalLayout>
                  )}
                </StyledInnerCellWrapper>
              </TableCell>
            );
          },
        )}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function GeneralTable<
  Data extends { id: number | string },
  RowActions extends GeneralListActionKind[] = [],
>({
  actions,
  columns,
  data,
  dependencies,
  emptyComponent,
  getA11yRowProps,
  id,
  isLoading,
  isSelected,
  onRowClick,
  rowClickDisabled,
  setOrder,
  setOrderBy,
  sortBy,
  sortOrder,
  tour,
}: GeneralTableProps<Data, RowActions>) {
  const { tableBody, visibleColumns } = useGetTableBody<Data, RowActions>({
    actions,
    columns,
    data,
    dependencies,
    getA11yRowProps,
    id,
    isSelected,
    onRowClick,
    rowClickDisabled,
    tour,
  });

  const handleRequestSort = (property: string) => {
    const isAsc = sortBy === property && sortOrder === SORT_ORDER_ASC;
    setOrder?.(isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC);
    setOrderBy?.(property);
  };

  if (!data?.length && !isLoading) {
    return <>{emptyComponent}</>;
  }

  if (isLoading || !tableBody?.length) {
    return <Loading id={id} />;
  }

  return (
    <StyledGeneralTable data-testid={id}>
      <EnhancedTableHead
        visibleColumns={visibleColumns}
        order={sortOrder}
        orderBy={sortBy}
        onRequestSort={handleRequestSort}
      />
      <TableBody>{tableBody}</TableBody>
    </StyledGeneralTable>
  );
}
