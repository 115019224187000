import { useEffect } from "react";

export function useWarnBeforeExit({ skip }: { skip?: boolean } = {}) {
  useEffect(() => {
    const isLocalhost = window?.location?.host == "localhost:9090";

    function handleClose(event: any) {
      if (skip) return;

      event.preventDefault();
      event.returnValue = "";
    }

    if (!isLocalhost) {
      window.addEventListener("beforeunload", handleClose);

      return () => {
        window.removeEventListener("beforeunload", handleClose);
      };
    }

    console.warn("useWarnBeforeExit: Disabled due to localhost environment.");
  }, [skip]);
}
