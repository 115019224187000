/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
/* eslint-disable no-cond-assign */
import { useLayoutEffect, useState } from "react";

export default function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    let hidden = "hidden";

    function onchange(evt: Event) {
      const v = "visible";
      const h = "hidden";
      const evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h,
      };

      evt = evt || window.event;
      let result;
      // @ts-ignore
      if (evt.type in evtMap) result = evtMap[evt.type];
      // @ts-ignore
      else result = this[hidden] ? "hidden" : "visible";

      setIsVisible(result === v);
    }

    // addEventListener(type: string, listener: EventListener, optionsOrUseCapture?: EventListenerOptionsOrUseCapture): void;
    // addEventListener(type: MessageEventTypes, listener: MessageEventListener, optionsOrUseCapture?: EventListenerOptionsOrUseCapture): void;

    // Standards:
    if (hidden in document)
      document.addEventListener("visibilitychange", onchange);
    else if ((hidden = "mozHidden") in document)
      document.addEventListener("mozvisibilitychange", onchange);
    else if ((hidden = "webkitHidden") in document)
      document.addEventListener("webkitvisibilitychange", onchange);
    else if ((hidden = "msHidden") in document)
      document.addEventListener("msvisibilitychange", onchange);
    // IE 9 and lower:
    else if ("onfocusin" in document)
      // @ts-ignore
      document.onfocusin = document.onfocusout = onchange;
    // All others:
    else
      window.onpageshow =
        window.onpagehide =
        window.onfocus =
        window.onblur =
          onchange;
  }, []);

  return isVisible;
}
