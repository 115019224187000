import { differenceInDays, fromUnixTime, getUnixTime } from "date-fns";
import { RECEIVER_FILTER_SETTING_PERSIST } from "dsl/organisms/Filters/consts";
import React, { useEffect, useReducer } from "react";

const expirationId = (id: string) => `_expiration_${id}`;

const didExpire = (id: string, expirationInDays: number) => {
  const persistedRawUpdatedAt = window.localStorage.getItem(expirationId(id));
  const updatedAt = persistedRawUpdatedAt
    ? fromUnixTime(Number(persistedRawUpdatedAt))
    : undefined;
  const expired =
    !updatedAt || differenceInDays(new Date(), updatedAt) >= expirationInDays;
  return expired;
};

// persist reducer in local storage
// stored values must be stringified
// not recommended for complex state objects
export default function usePersistReducer<
  State extends {
    [index: string]: any;
    [RECEIVER_FILTER_SETTING_PERSIST]?: boolean;
  },
  Actions,
>(
  id: string,
  reducerFn: React.Reducer<State, Actions>,
  initialState: State,
  expirationInDays?: number,
): [State, React.Dispatch<Actions>] {
  const [state, setState] = useReducer<React.Reducer<State, Actions>, State>(
    reducerFn,
    initialState,
    (initialState) => {
      const persistedRawState = window.localStorage.getItem(id);
      if (persistedRawState == null) return initialState;

      if (expirationInDays) {
        const expired = didExpire(id, expirationInDays);
        if (expired) return initialState;
      }

      return JSON.parse(persistedRawState);
    },
  );

  useEffect(() => {
    if (expirationInDays) {
      const expired = didExpire(id, expirationInDays);
      if (expired) {
        window.localStorage.removeItem(id);
        window.localStorage.removeItem(expirationId(id));
      }
    }
    if (state?.[RECEIVER_FILTER_SETTING_PERSIST] === false) {
      window.localStorage.removeItem(id);
      return;
    }
    const rawState = JSON.stringify(state);
    const persistedRawState = window.localStorage.getItem(id);
    if (rawState != persistedRawState) {
      window.localStorage.setItem(id, rawState);
      if (expirationInDays) {
        window.localStorage.setItem(
          expirationId(id),
          String(getUnixTime(new Date())),
        );
      }
    }
  }, [id, state]);

  return [state, setState];
}
