import { DOCUMENT_TERMS_AND_CONDITIONS } from "core/consts";
import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import InfoCaption from "ds_legacy/components/InfoCaption";
import LinkV2, { useExternalLinkLabel } from "ds_legacy/components/LinkV2";
import { margin } from "ds_legacy/materials/metrics";
import { Caption, FONT_SIZE_14 } from "ds_legacy/materials/typography";
import styled from "styled-components";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import { useLegalDocuments } from "./LegalDocuments";

const TermsAndConditionsBox = styled.div`
  margin: ${margin(2)};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const TermsAndConditions = () => {
  const { getDocument } = useLegalDocuments();
  const termsAndConditions = getDocument(DOCUMENT_TERMS_AND_CONDITIONS);
  const translations = useTranslations();
  const externalLinkLabel = useExternalLinkLabel();

  if (!termsAndConditions?.needs_to_sign) return null;

  return (
    <>
      <TermsAndConditionsBox>
        <CheckboxInputField
          elementName="terms_and_conditions"
          label={
            <Caption
              margin={margin(0)}
              whiteSpace="normal"
              fontSize={FONT_SIZE_14}
            >
              <TranslationComposition
                translations={translations.termsAndConditions.accept}
                withOptions
              >
                {([before, link, after]) => (
                  <>
                    {before}
                    <LinkV2
                      aria-label={externalLinkLabel(
                        termsAndConditions.url,
                        "website",
                      )}
                      href={termsAndConditions.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link}
                    </LinkV2>
                    {after}
                  </>
                )}
              </TranslationComposition>
            </Caption>
          }
          required
        />
      </TermsAndConditionsBox>
      {termsAndConditions.needs_to_sign && (
        <InfoCaption
          text={translations.legal.differencesInTCVersions}
          margin={margin(0, 3)}
        />
      )}
    </>
  );
};
