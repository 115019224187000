import bowser, { Parser } from "bowser";
import BrowserSupport from "dsl/atoms/BrowserSupport";
import React, { useContext, useLayoutEffect, useState } from "react";

export type BrowserContextType = {
  browser: Parser.Parser | null;
  browserName: string;
  browserVersion: string;
  isChrome: boolean;
  isEdge: boolean;
  isFirefox: boolean;
  isIE: boolean;
  isMS: boolean;
  isOldEdge: boolean;
};

export const defaultContext: BrowserContextType = {
  browser: null,
  browserName: "",
  browserVersion: "",
  isIE: false,
  isEdge: false,
  isOldEdge: false,
  isMS: false,
  isFirefox: false,
  isChrome: false,
};

export const BrowserContext =
  React.createContext<BrowserContextType>(defaultContext);

export const useBrowserContext = () => useContext(BrowserContext);

export default function BrowserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [browserState, setBrowserState] = useState(defaultContext);

  useLayoutEffect(() => {
    const browser = bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowser().name || "";
    const browserVersion = browser.getBrowser().version || "";

    setBrowserState({
      browser,
      browserName,
      browserVersion,
      isIE: browserName === "Internet Explorer",
      isEdge: browserName === "Microsoft Edge",
      isOldEdge:
        browserName === "Microsoft Edge" && parseInt(browserVersion) < 80,
      isMS:
        browserName === "Internet Explorer" || browserName === "Microsoft Edge",
      isFirefox: browserName === "Firefox",
      isChrome: browserName === "Chrome",
    });
  }, [window.navigator.userAgent]);

  return (
    <BrowserContext.Provider value={browserState}>
      <BrowserSupport>{children}</BrowserSupport>
    </BrowserContext.Provider>
  );
}
